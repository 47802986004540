/*
 * Copyright 2020 Marc Nuri
 *
 * Licensed under the Apache License, Version 2.0 (the 'License');
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an 'AS IS' BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
import React from 'react';

const Node = ({...props}) => (
  <svg viewBox="0 0 18.035 17.5" {...props}>
    <path
      d="M8.958.463a1.136 1.126 0 00-.435.11l-5.94 2.838a1.136 1.126 0 00-.614.764L.504 10.55a1.136 1.126 0 00.154.864 1.136 1.126 0 00.064.09l4.112 5.111a1.136 1.126 0 00.888.424l6.592-.001a1.136 1.126 0 00.888-.424l4.11-5.112a1.136 1.126 0 00.22-.953l-1.468-6.375a1.136 1.126 0 00-.615-.764L9.51.573a1.136 1.126 0 00-.55-.11z"
      fill="#326ce5"
    />
    <path
      d="M8.955.002a1.199 1.19 0 00-.459.117l-6.27 2.995a1.199 1.19 0 00-.65.806L.03 10.65a1.199 1.19 0 00.163.912 1.199 1.19 0 00.068.095l4.34 5.396a1.199 1.19 0 00.937.447l6.96-.001a1.199 1.19 0 00.937-.447l4.339-5.397a1.199 1.19 0 00.231-1.006l-1.549-6.73a1.199 1.19 0 00-.648-.806L9.537.118a1.199 1.19 0 00-.582-.116zm.003.461a1.136 1.126 0 01.55.11L15.45 3.41a1.136 1.126 0 01.615.764l1.467 6.375a1.136 1.126 0 01-.22.953l-4.109 5.113a1.136 1.126 0 01-.888.423l-6.593.001a1.136 1.126 0 01-.888-.424l-4.11-5.111a1.136 1.126 0 01-.065-.09 1.136 1.126 0 01-.154-.863l1.465-6.375a1.136 1.126 0 01.614-.764L8.523.574a1.136 1.126 0 01.435-.11z"
      style={{
        lineHeight: "normal",
        InkscapeFontSpecification: "Sans",
        textIndent: 0,
        textAlign: "start",
        textDecorationLine: "none",
        textTransform: "none",
        marker: "none",
      }}
      color="#000"
      fontWeight={400}
      fontFamily="Sans"
      overflow="visible"
      fill="#fff"
    />
    <path
      d="M9 3.836c-.139-.004-3.996 1.892-4.046 1.99-.121.233-.999 4.28-.947 4.368.03.05.662.852 1.404 1.78l1.35 1.689h2.221l2.222.001 1.413-1.765 1.414-1.766-.495-2.168c-.272-1.193-.516-2.189-.54-2.213-.07-.067-3.921-1.914-3.996-1.916zm.196.948l.909.263-.91.264-.908-.264zm-.91.364l.852.253-.004 1.184-.847-.47zm1.819 0v.967l-.847.47L9.254 5.4zM7.907 6.763l.91.264-.91.263-.909-.263zm2.209 0l.909.264-.91.263-.908-.263zm-3.118.364l.851.253-.004 1.184L7 8.094zm1.818 0v.968l-.847.469-.004-1.184zm.39 0l.852.253-.004 1.184-.847-.47zm1.819 0v.968l-.847.469-.004-1.184zm-3.45 1.515c.27.01.061.282.369.428.327.156.395-.235.6.064.207.299-.183.223-.154.585.03.362.402.225.246.552-.155.328-.285-.047-.584.159-.298.206.006.46-.355.489-.362.029-.102-.27-.43-.426-.327-.156-.395.235-.6-.064-.207-.299.183-.223.154-.585-.029-.361-.402-.225-.246-.552.156-.328.285.047.584-.159.299-.206-.006-.46.356-.489a.56.56 0 01.06-.002zm2.336.363c.483.014.015.468.456.665.441.198.467-.454.8-.103.332.352-.32.341-.148.793.172.452.652.009.638.492-.014.484-.467.015-.665.456-.197.442.454.468.103.8-.352.332-.34-.32-.792-.148-.452.173-.01.652-.493.638-.483-.013-.014-.467-.456-.664-.441-.198-.467.454-.8.102-.332-.351.32-.34.148-.792-.173-.452-.652-.009-.638-.492.014-.484.467-.015.665-.457.197-.441-.454-.467-.103-.8.351-.331.34.32.792.148.452-.172.01-.651.493-.638zm-2.315.254a.532.532 0 100 1.063.532.532 0 000-1.063zm2.265.48a1.062 1.062 0 10-.001 2.125 1.062 1.062 0 000-2.124z"
      fill="#fff"
    />
  </svg>
);

export default Node;
