/*
 * Copyright 2020 Marc Nuri
 *
 * Licensed under the Apache License, Version 2.0 (the 'License');
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an 'AS IS' BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
import React from 'react';

const StatefulSet = ({...props}) => (
  <svg viewBox="0 0 18.035 17.5" {...props}>
    <path
      d="M8.994.463a1.136 1.126 0 00-.436.11l-5.94 2.838a1.136 1.126 0 00-.614.764L.54 10.55a1.136 1.126 0 00.154.864 1.136 1.126 0 00.065.09l4.11 5.111a1.136 1.126 0 00.889.424l6.593-.001a1.136 1.126 0 00.888-.424l4.11-5.112a1.136 1.126 0 00.219-.953l-1.468-6.375a1.136 1.126 0 00-.614-.764L9.545.573a1.136 1.126 0 00-.551-.11z"
      fill="#326ce5"
    />
    <path
      d="M8.99.002a1.199 1.19 0 00-.458.117L2.26 3.114a1.199 1.19 0 00-.649.806L.066 10.65a1.199 1.19 0 00.162.912 1.199 1.19 0 00.068.095l4.34 5.396a1.199 1.19 0 00.938.447l6.96-.001a1.199 1.19 0 00.937-.447l4.338-5.397a1.199 1.19 0 00.232-1.006l-1.55-6.73a1.199 1.19 0 00-.648-.806L9.573.118A1.199 1.19 0 008.99.002zm.003.461a1.136 1.126 0 01.551.11l5.94 2.837a1.136 1.126 0 01.615.764l1.468 6.375a1.136 1.126 0 01-.22.953l-4.11 5.113a1.136 1.126 0 01-.887.423l-6.593.001a1.136 1.126 0 01-.888-.424L.758 11.504a1.136 1.126 0 01-.065-.09 1.136 1.126 0 01-.154-.863l1.465-6.375a1.136 1.126 0 01.615-.764L8.559.574a1.136 1.126 0 01.434-.11z"
      style={{
        lineHeight: "normal",
        InkscapeFontSpecification: "Sans",
        textIndent: 0,
        textAlign: "start",
        textDecorationLine: "none",
        textTransform: "none",
        marker: "none",
      }}
      color="#000"
      fontWeight={400}
      fontFamily="Sans"
      overflow="visible"
      fill="#fff"
    />
    <g>
      <path
        d="M7.33 5.047h6.524v4.584H7.33z"
        fill="none"
        stroke="#fff"
        strokeWidth={0.529}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray="1.58743761,1.58743761"
        strokeDashoffset={3.667}
      />
      <path
        d="M5.79 6.459h6.525v4.583H5.79z"
        fill="#326ce5"
        fillRule="evenodd"
        stroke="#fff"
        strokeWidth={0.529}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeDasharray="1.58743761,1.58743761"
        strokeDashoffset={3.879}
      />
      <path
        d="M4.252 7.87h6.525v4.583H4.252z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M4.252 7.87h6.525v4.583H4.252z"
        fill="none"
        stroke="#fff"
        strokeWidth={0.529}
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <path
        d="M4.785 9.144c0-.43 1.222-.777 2.729-.777 1.507 0 2.73.348 2.73.777 0 .43-1.223.778-2.73.778s-2.73-.348-2.73-.778z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M10.243 9.144c0 .43-1.222.778-2.729.778-1.507 0-2.73-.348-2.73-.778 0-.43 1.223-.777 2.73-.777s2.73.348 2.73.777v2.104c0 .429-1.223.777-2.73.777s-2.73-.348-2.73-.777V9.144"
        fill="none"
      />
      <path
        d="M10.243 9.144h0c0 .43-1.222.778-2.729.778-1.507 0-2.73-.348-2.73-.778h0c0-.43 1.223-.777 2.73-.777s2.73.348 2.73.777v2.104c0 .429-1.223.777-2.73.777s-2.73-.348-2.73-.777V9.144"
        fill="none"
        stroke="#326ce5"
        strokeWidth={0.529}
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
    </g>
  </svg>
);

export default StatefulSet;
