/*
 * Copyright 2020 Marc Nuri
 *
 * Licensed under the Apache License, Version 2.0 (the 'License');
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an 'AS IS' BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
import React from 'react';

const Secret = ({...props}) => (
  <svg viewBox="0 0 18.035 17.5" {...props}>
    <path
      d="M8.958.463a1.136 1.126 0 00-.435.11l-5.94 2.838a1.136 1.126 0 00-.614.764L.504 10.55a1.136 1.126 0 00.154.864 1.136 1.126 0 00.064.09l4.112 5.111a1.136 1.126 0 00.888.424l6.592-.001a1.136 1.126 0 00.888-.424l4.11-5.112a1.136 1.126 0 00.22-.953l-1.468-6.375a1.136 1.126 0 00-.615-.764L9.51.573a1.136 1.126 0 00-.55-.11z"
      fill="#326ce5"
    />
    <path
      d="M8.955.002a1.199 1.19 0 00-.459.117l-6.27 2.995a1.199 1.19 0 00-.65.806L.03 10.65a1.199 1.19 0 00.163.912 1.199 1.19 0 00.068.095l4.34 5.396a1.199 1.19 0 00.937.447l6.96-.001a1.199 1.19 0 00.937-.447l4.339-5.397a1.199 1.19 0 00.231-1.006l-1.549-6.73a1.199 1.19 0 00-.648-.806L9.537.118a1.199 1.19 0 00-.582-.116zm.003.461a1.136 1.126 0 01.55.11L15.45 3.41a1.136 1.126 0 01.615.764l1.467 6.375a1.136 1.126 0 01-.22.953l-4.109 5.113a1.136 1.126 0 01-.888.423l-6.593.001a1.136 1.126 0 01-.888-.424l-4.11-5.111a1.136 1.126 0 01-.065-.09 1.136 1.126 0 01-.154-.863l1.465-6.375a1.136 1.126 0 01.614-.764L8.523.574a1.136 1.126 0 01.435-.11z"
      style={{
        lineHeight: "normal",
        InkscapeFontSpecification: "Sans",
        textIndent: 0,
        textAlign: "start",
        textDecorationLine: "none",
        textTransform: "none",
        marker: "none",
      }}
      color="#000"
      fontWeight={400}
      fontFamily="Sans"
      overflow="visible"
      fill="#fff"
    />
    <g transform="translate(-.936 -.4)">
      <path
        d="M10.414 8.091h3.709M8.298 5.975h5.825M5.784 5.975h1.458"
        fill="none"
        stroke="#fff"
        strokeWidth={0.794}
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <path
        d="M7.718 8.432h0c0-1.167 1.011-2.112 2.259-2.112 1.247 0 2.258.945 2.258 2.112h-1.056 0c0-.583-.538-1.056-1.202-1.056-.664 0-1.202.473-1.202 1.056z"
        fill="#fff"
        fillRule="evenodd"
        stroke="#326ce5"
        strokeWidth={0.265}
        strokeLinecap="square"
        strokeMiterlimit={10}
      />
      <path
        d="M10.414 10.208h3.709M8.298 12.325h5.825M5.784 12.325h1.458"
        fill="none"
        stroke="#fff"
        strokeWidth={0.794}
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <path
        d="M7.162 8.37h5.617v3.657H7.162z"
        fill="#fff"
        fillRule="evenodd"
        stroke="#326ce5"
        strokeWidth={0.265}
        strokeLinecap="square"
        strokeMiterlimit={10}
      />
      <circle r={0.555} cy={10.198} cx={9.971} fill="#326ce5" />
    </g>
  </svg>
);

export default Secret;
