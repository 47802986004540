/*
 * Copyright 2020 Marc Nuri
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
import api from './api';
import reducer from './reducer';
import selectors from './selectors';
import List from './List';
import PortList from './PortList';
import ServicesDetailPage from './ServicesDetailPage';
import ServicesEditPage from './ServicesEditPage';
import ServicesPage from './ServicesPage';
import Type from './Type';

const services = {};

services.api = api;
services.reducer = reducer;
services.selectors = selectors;
services.List = List;
services.PortList = PortList;
services.ServicesDetailPage = ServicesDetailPage;
services.ServicesEditPage = ServicesEditPage;
services.ServicesPage = ServicesPage;
services.Type = Type;

export default services;
