/*
 * Copyright 2020 Marc Nuri
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

import redux from '../redux';

const defaultState = {
  expandedItems: [],
  scroll: {scrollTop: 0, scrollLeft: 0}
};

const reducer = (state = defaultState, action = {}) => {
  const {actions: {Types}} = redux;
  switch (action.type) {
    case Types.SIDEBAR_SCROLL: {
      return {...state, scroll: action.payload};
    }
    case Types.SIDEBAR_TOGGLE_ITEM: {
      const newState = {...state};
      const item = action.payload;
      if (state.expandedItems.includes(item)) {
        newState.expandedItems = state.expandedItems.filter(i => i !== item);
      } else {
        newState.expandedItems = [...state.expandedItems, item];
      }
      return newState;
    }
    default:
      return {...state};
  }
};

export default reducer;